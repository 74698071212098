@font-face {
  font-family: 'openhuninn';
  src: url('https://d2h4b4qokk4jtl.cloudfront.net/fonts/jf-openhuninn-2.1.woff');
  unicode-range: U+4E00-9FFF;
}

html, body, #root {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  font-family: openhuninn,-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica Neue,Helvetica,Roboto,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji",Segoe UI Symbol;
  background-color: var(--main-bg-1);
}

#root{
  transition: background-color 0.8s;
}

.render-container{
  margin: 0 auto;
  max-width: 770px;
  padding-bottom: 200px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.text-center {
  text-align: center;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

a{
  color: var(--main-2);
}

a:link{
  text-decoration: none;
}

@media screen and (max-width: 576px) {
  .render-container{
    margin: 0 auto;
    max-width: 800px;
    padding-bottom: 100px;
    padding-left: 3.5%;
    padding-right: 3.5%;
  }
  
  .app-container{
    padding-bottom: 100px;
  }
}