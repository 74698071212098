#Search-Bar {
    /* margin-top: 16px; */
    margin-bottom: 32px;
    background-color: var(--main-bg-1);
}

#Search-Bar input[type=text] {
    border-color: var(--support-1);
    border-style: solid;
    border-width: 0px 0px 1px 0px;
    height: 30px;
    text-align: center;
    padding-left: 5px;
    margin-right: 5px;
    background-color: var(--main-bg-1);
    max-width: 250px;
}

#Search-Bar input[type=text]:focus{
    outline-width: 0px;
}

#Search-Bar button[type=submit]{
    height: 28px;
    border-style: solid;
    border-width: 0px 0px 0px 0px;
    color: var(--text-1);
}
