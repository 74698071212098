#Banner {
    margin-top: 40px;
    margin-bottom: 40px;
}

#Banner .blog-icon{
    color: white;
    font-size: 12px;
    background-color: var(--main-2);
    border-radius: 4px;
    padding: 5px;
    font-weight: 700;
    height: 18px;
    position: relative;
    top: -8px;
}

#Banner h1 {
    font-size: 32px;
    margin-bottom: 3px;
}

#Banner h1 img{
    display: inline;
    height: 36px;
    padding-bottom: 6px;
    padding-right: 6px;
}

#Banner .home-page-title {
    color: var(--text-1);
    text-decoration: none;
}

#Banner a{
    color: var(--main-2);
}
  
#Banner a:link{
    text-decoration: none;
}

#Banner .sub-item-container{
    margin-top: 3px;
    margin-bottom: 8px;
    height: 24px;
}

#Banner small{
    color: var(--text-2);
}

#Banner small.spec-page{
    color: var(--text-2);
    margin-left: 3px;
    margin-right: 3px;
    border-radius: 6px;
    padding: 3px;
}

#Banner small.spec-page > a:hover{
    /* color: var(--main-2); */
    color: var(--main-1);
}

#Banner small.spec-page a{
    color: var(--text-2);
}

#Banner span.sep::before {
    content: '<sep>';
    color: var(--text-2);
    font-style: italic;
    font-size: 8px;
}