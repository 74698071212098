#Post-Block{
    margin-bottom: 50px;
    border-color: var(--support-2);
    border-style: solid;
    border-width: 0px 0px 1px 0px;
    /* border-radius: 5px; */
}

#Post-Block hr{
    border: 1px solid var(--support-1);
    margin-bottom: 12px;
}

#Post-Block small{
    color: var(--text-1);
}

#Post-Block .post-date{
    color: var(--text-2);
}

#Post-Block .title-badge{
    width: 100px;
    margin-left: 10px;
    position: relative;
    top: 4px;
}

#Post-Block a.post-title{
    font-size: 18px;
}

@media screen and (max-width: 768px) {
    #Post-Block{
        padding: 12px;
        max-width: 100%;     
    }
}

@media screen and (max-width: 576px) {
    #Post-Block{
        width: 100%;
        padding: 12px;
        box-sizing: border-box;
        margin: 0 auto;
        margin-bottom: 20px;
    }
}