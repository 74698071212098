#Footer{
    text-align: center;
    color: var(--text-2);
    font-size: 10pt;
    position: fixed;
    bottom: 2.5%;
    right: 2.5%;
}

#Footer a{
    color: var(--main-3);
}

#Footer a:link{
    text-decoration: none;
}

#Footer div{
    margin-bottom: 5px;
}


@media screen and (max-width: 768px){
    #Footer{
        color: var(--text-2);
        font-size: 10pt;
        position: initial;
    }
}