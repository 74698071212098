#App a{
    color: var(--main-2);
}
  
#App a:link{
    text-decoration: none;
}

#App small{
    color: var(--text-2);
}

#App ol {
    display: block;
    list-style-type: decimal;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0;
    margin-right: 0;
    padding-left: 40px;
}

#App ul {
    display: block;
    list-style-type: disc;
    margin-top: 1em;
    margin-bottom: 1 em;
    margin-left: 0;
    margin-right: 0;
    padding-left: 40px;
}

.context {
    max-width: 800px;
    min-width: 600px;
    margin: 0 auto;
    position: relative;
}

@media screen and (max-width: 768px) {
    .context {
        width: 100%;
        margin: 0 auto;
        position: relative;

    }
}

@media screen and (max-width: 576px) {
    .context {
        margin-left: 0px;
        box-sizing: border-box;
        max-width: 576px;
        min-width: 380px;
        position: relative;
    }
}