.drkmd-toggle-button{
    visibility: hidden !important;
}

#Corner-Menu {
    color: var(--support-1);
    font-size: 3px;
    position: fixed;
    bottom: 55px;
    right: 3.4%;
}

#Corner-Menu .link {
    height: 42px;
    width: 42px;
    border-radius: 50%;
    display: block;
    margin-top: 10px;
}

#Corner-Menu .link svg,
#Corner-Menu .link a {
    color: var(--support-2);
    background-color: rgba(0, 0, 0, 0);
    border: none;
}

#Corner-Menu .link svg:hover,
#Corner-Menu .link a:hover{
    color: var(--support-1);
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    #Corner-Menu {
        color: var(--support-2);
        font-size: 3px;
        position: fixed;
        bottom: 30px;
        right: 6%;
    }

    #Corner-Menu .link {
        height: 36px;
        width: 36px;
        border-radius: 50%;
        display: block;
        margin-top: 15px;
    }
}