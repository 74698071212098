.post-title{
    color: var(--text-1);
}
.document-info{
    margin-top: 10px;
    margin-bottom: 25px;
    font-size: 14px;
}

.document-info .post-tag{
    background-color: var(--support-1);
    color: var(--text-3);
    margin-right: 5px;
    font-size: 12px;
    padding: 2px 6px 2px 6px;
    border-radius: 3px;
}

#MD {
    line-height: 26px;
    color: var(--text-1);
}

#MD a {
    color: var(--main-2);
    text-decoration: underline;
    text-decoration-color: var(--main-2);
}

#MD ol {
    display: block;
    list-style-type: decimal;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0;
    margin-right: 0;
    padding-left: 40px;
}

#MD ul {
    display: block;
    list-style-type: disc;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0;
    margin-right: 0;
    padding-left: 40px;
}

#MD p {
    line-height: 2.2;
    margin-bottom: 24px;
}

#MD .footer {
    color: var(--text-1);
    margin-top: 60px;
}

#MD .footer a {
    color: var(--main-3);
}

#MD h2 {
    text-decoration: underline;
    text-decoration-color: var(--main-3);
    line-height: 60px;
    font-size: 28px;
    margin-top: 18px;
    margin-bottom: 18px;
}

#MD h3 {
    text-decoration: underline;
    text-decoration-color: var(--main-3);;
    line-height: 40px;
    font-size: 24px;
    margin-top: 18px;
    margin-bottom: 18px;
}

#MD h4 {
    text-decoration: underline;
    text-decoration-color: var(--support-1);
    line-height: 40px;
    font-size: 20px;
    margin-top: 18px;
    margin-bottom: 18px;
}

#MD h5 {
    text-decoration: underline;
    text-decoration-color: var(--support-2);
    line-height: 40px;
    font-size: 16px;
    margin-top: 16px;
    margin-bottom: 16px;
    font-weight: 500;
}

#MD h6 {
    text-decoration: none;
    text-decoration-color: var(--support-2);
    line-height: 40px;
    font-size: 14px;
    margin-top: 14px;
    margin-bottom: 16px;
    font-weight: 500;
}

#MD pre {
    max-width: 100%;
    position: relative;
    margin-bottom: 25px;
} 

#MD pre div{
    border-radius: 0px !important;
}

/* inline code */
#MD code {
    box-sizing: border-box;
    font-family: monospace;
    color: var(--text-1);
    border-radius: 2px
} 

#MD pre div::before {
    content: ":: Code Block ::";
    width: 100%;
    height: 10px;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: var(--main-2);
    text-align: right;
    line-height: 10px;
    font-size: 8px;
    color: var(--text-3);
    padding-right: 2px;
}
#MD blockquote::before{
    content: "! Note";
    width: 100%;
    height: 10px;
    position: absolute;
    top: -10px;
    left: 0;
    background-color: var(--support-2);
    text-align: left;
    line-height: 10px;
    font-size: 8px;
    color: var(--text-2);
    padding-left: 2px;
}


#MD blockquote {
    position: relative;
    margin-top: 16px;
    background-color: var(--support-3);
    font-size: smaller;
    margin-bottom: 34px;
    padding: 8px;
    padding-left: 12px;
    vertical-align: middle;
}

#MD blockquote p {
    margin-bottom: 0px;
}

/* width */
#MD pre ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

/* Track */
#MD pre ::-webkit-scrollbar-track {
    background: var(--support-3);
}

/* Handle */
#MD pre ::-webkit-scrollbar-thumb {
    background: var(--support-2);
    border-radius: 0px;
}

/* Handle on hover */
#MD pre ::-webkit-scrollbar-thumb:hover {
    background: var(--support-1);
}

#MD .cursor-pointer {
    cursor: pointer;
}

#MD img {
    max-width: 100%;
    margin-top: 12px;
    margin-bottom: 12px;
}

#MD .run-btn {
    color: var(--text-1);
    position: absolute;
    right: 8px;
    top: 3px;
    cursor: pointer;
}

#MD .exif{
    font-family: monospace;
    color: var(--text-1);
    font-style: italic;
    border-width: 1px;
    border-style: solid;
    border-color: var(--support-3);
    padding: 12px 12px 0px 12px;
}

#MD .exif img{
    margin-top: 0px;
    margin-bottom: 0px;
}

#MD .exif-text{
    margin-left: 5px;
    font-size: 12px;
    height: 48px;
}

#MD .exif-item{
    padding-right: 5px;
    margin-left: 6px;
    color: var(--text-2);
}


#MD .md-table-container tr:first-child{
    border-width: 1px 0px 2px 0px;
    border-style: solid;
    border-color: var(--main-1);;
}

#MD .md-table-container tr{
    border-width: 1px 0px 1px 0px;
    border-style: solid;
    border-color: var(--support-1);;
}

#MD .md-table-container th, td{
    padding-right: 10px;
    padding-left: 3px;
}