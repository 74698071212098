#TOC .activate-h2 {
    color: var(--text-1);
    text-decoration-color: var(--main-3);
}

#TOC .activate-h3 {
    color: var(--text-1);
    text-decoration-color: var(--main-3);
}


#TOC li {
    color: var(--text-2);
    line-height: 1.6;
}

.box {
    width: 100%;
    padding-bottom: 5px;
}

.ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}